// var baseURL='https://applet.qiannianjituan.com'
export const baseURL = 'https://adminbayj.qiannianjituan.com' //正式 
// export const baseURL = 'https://applet.qiannianjituan.com' //测试
// request.defaults.baseURL = 'https://store.qiannianjituan.com/'


// exports.baseURL = request 
// axios.create({
//   baseURL:'https://applet.qiannianjituan.com'
// });
