<template>
  <div>
    <div
      v-show="hidden"
      style="
        position: fixed;
        z-index: 999;
        width: 80px;
        height: 80px;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;
        line-height: 80px;
        top: 50%;
        left: 50%;
        margin-top: -40px;
        margin-left: -40px;
        border-radius: 8px;
        color: #fff;
      "
    >
      {{ msg }}
    </div>
    <!-- 上传组件辅助-->
    <el-upload
      class="avatar-uploader-editor"
      :action="serverUrl"
      name="image"
      :headers="header"
      :data="data"
      :show-file-list="false"
      :on-success="uploadSuccess"
      :before-upload="be_up"
      :on-error="uploadError"
    >
    </el-upload>
    <el-upload
      class="avatar-uploader-editor-video"
      :action="serverUrl"
      name="image"
      :headers="header"
      :data="data"
      :show-file-list="false"
      :http-request="postImage"
    >
    </el-upload>
    <!-- <yl-upload
      class="avatar-uploader-editor-video"
      :action="serverUrl"
      :on-progress="on_progress"
      :data="chunkData"
      :headers="header"
      :show-file-list="false"
      :before-upload="be_up_video"
      :on-success="uploadSuccessVideo"
      :chunk-size="1024 * 1024 * 10"
      :thread="1"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </yl-upload> -->

    <el-upload
      class="avatar-uploader-editor-voice"
      :action="serverUrl"
      name="image"
      :headers="header"
      :data="data"
      :show-file-list="false"
      :on-success="uploadSuccessVoice"
      :before-upload="be_up"
      :on-error="uploadError"
    >
    </el-upload>

    <quill-editor
      @change="onEditorChange($event)"
      v-model="content"
      ref="myQuillEditor"
      class="ql-snow"
      style="height: 330px"
      :options="editorOption"
    >
      <div id="toolbar" slot="toolbar">
        <button class="ql-bold" title="加粗">Bold</button>
        <button class="ql-italic" title="斜体">Italic</button>
        <button class="ql-underline" title="下划线">underline</button>
        <button class="ql-strike" title="删除线">strike</button>
        <button class="ql-blockquote" title="引用"></button>
        <button class="ql-code-block" title="代码"></button>
        <button class="ql-header" value="1" title="标题1"></button>
        <button class="ql-header" value="2" title="标题2"></button>
        <button class="ql-list" value="ordered" title="有序列表"></button>
        <button class="ql-list" value="bullet" title="无序列表"></button>
        <select class="ql-header" title="段落格式">
          <option selected>段落</option>
          <option value="1">标题1</option>
          <option value="2">标题2</option>
          <option value="3">标题3</option>
          <option value="4">标题4</option>
          <option value="5">标题5</option>
          <option value="6">标题6</option>
        </select>
        <select class="ql-size" title="字体大小">
          <option value="10px">10px</option>
          <option value="12px">12px</option>
          <option value="14px">14px</option>
          <option value="16px" selected>16px</option>
          <option value="18px">18px</option>
          <option value="20px">20px</option>
          <option value="30px">30px</option>
        </select>
        <select class="ql-font" title="字体">
          <option value="SimSun">宋体</option>
          <option value="SimHei">黑体</option>
          <option value="Microsoft-YaHei">微软雅黑</option>
          <option value="KaiTi">楷体</option>
          <option value="FangSong">仿宋</option>
          <option value="Arial">Arial</option>
        </select>
        <select class="ql-color" value="color" title="字体颜色"></select>
        <select
          class="ql-background"
          value="background"
          title="背景颜色"
        ></select>
        <select class="ql-align" value="align" title="对齐"></select>
        <button class="ql-clean" title="清除字体样式"></button>
        <!-- <button class="ql-image" title="行高"></button> -->
        <button class="ql-image" title="图片"></button>
        <button class="ql-video" title="视频"></button>
        <button class="ql-audio" title="音频">
          <i class="el-icon-headset"></i>
        </button>
      </div>
    </quill-editor>
  </div>
</template>

<script>
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { Quill, quillEditor } from "vue-quill-editor";

import { baseURL } from "@/api/https.js";
import YlUpload from "../upload";
import OSS from "ali-oss";
// 自定义字体大小
let Size = Quill.import("attributors/style/size");
Size.whitelist = ["10px", "12px", "14px", "16px", "18px", "20px", "30px"];
Quill.register(Size, true);

// 自定义字体类型
var fonts = [
  "SimSun",
  "SimHei",
  "Microsoft-YaHei",
  "KaiTi",
  "FangSong",
  "Arial",
  "Times-New-Roman",
  "sans-serif",
  "宋体",
  "黑体",
];
var Font = Quill.import("formats/font");
Font.whitelist = fonts;
Quill.register(Font, true);

//视频标签插入（样式保持宽度100%）
import Video from "./video.js";
Quill.register(Video, true);

//音频标签插入
import Audio from "./audio.js";
Quill.register(Audio, true);
export default {
  props: {
    content: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  components: {
    YlUpload,
    quillEditor,
  },
  data() {
    return {
      hidden: false,
      msg: "",
      imgsrc: "",
      content: "",
      editorOption: {
        placeholder: "请输入", //文章初始化提示语
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              image: function (value) {
                if (value) {
                  // 触发input框选择图片文件
                  document
                    .querySelector(".avatar-uploader-editor input")
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  // 触发input框选择视频文件
                  document
                    .querySelector(".avatar-uploader-editor-video input")
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
              audio: function (value) {
                if (value) {
                  // 触发input框选择音频文件
                  document
                    .querySelector(".avatar-uploader-editor-voice input")
                    .click();
                } else {
                  this.quill.format("audio", false);
                }
              },
            },
          },
        },
      },
      header: { AdminToken: localStorage.getItem("admin_token") },
      serverUrl: baseURL + "/backend/uploadsfile/upload_image", //上传的图片服务器地址
      data: {
        type: "addnews",
      },
    };
  },
  methods: {
    be_up(res) {
      console.log(res);
      this.data.type = res.type.split("/")[1];
    },

    // 富文本图片上传成功
    uploadSuccess(res, file) {
      console.log(res, file);
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == 200) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片res.url为服务器返回的图片地址
        quill.insertEmbed(length, "image", this.imgsrc + res.data.imgurl);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },
    chunkData(option) {
      return {
        size: option.fileSize, // 总文件大小
        chunks: option.chunkTotal, // 所有切片数量
        chunk: option.chunkIndex, // 当前切片下标
        md5: option.chunkHash, // 单个切片hash
        filename: option.fileName, // 文件名
        fileHash: option.fileHash, // 整个文件hash
      };
    },
    be_up_video() {
      this.hidden = true;
      this.msg = "上传中...";
    },
    // uploadSuccessVideo(response, file, fileList) {
    //   //文件上传成功
    //   console.log(response[response.length-1])
    //   console.log(response, file, fileList);
    //   let quill = this.$refs.myQuillEditor.quill
    //   // 如果上传成功
    //   if (response[response.length-1].code == 200) {
    // 	  // this.loading=false
    //     // 获取光标所在位置
    // 	   this.msg='上传成功'
    // 	   this.hidden=false
    //     let length = quill.getSelection().index;
    //     // 插入图片res.url为服务器返回的地址
    //     quill.insertEmbed(length, 'video', response[response.length-1].data.imgurl)
    // 	console.log(response.data.imgurl)
    //     // 调整光标到最后
    //     quill.setSelection(length + 1)
    //   } else {
    //     this.$message.error('视频插入失败')
    //   }
    // },
    // on_progress(e){
    // 	console.log(e)

    // },
    //上传视频
    // uploadSuccessVideo(res,file) {
    //   let quill = this.$refs.myQuillEditor.quill
    //   // 如果上传成功
    //   if (res.code == 200) {
    //     // 获取光标所在位置
    //     let length = quill.getSelection().index;
    //     // 插入图片res.url为服务器返回的地址
    //     quill.insertEmbed(length, 'video', res.data.imgurl)
    //     // 调整光标到最后
    //     quill.setSelection(length + 1)
    //   } else {
    //     this.$message.error('视频插入失败')
    //   }
    // },
    async postImage(option) {
      console.log(new Date());
      var time = new Date();
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      if (m < 10) {
        m = 0 + "" + m;
      } else {
        m = m;
      }
      var d = time.getDate();
      var t = y + "" + m + "" + d;
      console.log(time.getFullYear());
      var date = Date.parse(new Date());
      try {
        //构建上传文件参数
        this.fileLoading = true;
        let self = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: "LTAI4GGEthRttGZ2HZ6aurLz",
          accessKeySecret: "3VEoZelrm8t8PlWAZm9mz1KyzznOOz",
          bucket: "qnstore",
        });
        let objectName =
          "/addnews/" +
          t +
          "/" +
          escape(date) +
          "." +
          option.file.name.split(".")[option.file.name.split(".").length - 1];
        let quill = self.$refs.myQuillEditor.quill;
        // 分片上传文件
        let result = await client.multipartUpload(objectName, option.file, {
          //进度条更新
          progress: async function (p) {
            self.percentage = parseInt(p * 100);
            self.isProgressVis = true;
          },
        });
        // if(result.res.requestUrls[0])
        // console.log(result.res.requestUrls[0].split('?')[0])
        let length = quill.getSelection().index;
        // 插入图片res.url为服务器返回的地址
        quill.insertEmbed(
          length,
          "video",
          result.res.requestUrls[0].split("?")[0]
        );
        // 调整光标到最后
        quill.setSelection(length + 1);
      } catch (error) {
        console.log(error);
        this.$message.error(error.message);
      }
    },
    //上传音频-处理很重要！！！！
    uploadSuccessVoice(res, file) {
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == 0) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        let BlockEmbed = Quill.import("blots/block/embed");
        class AudioBlot extends BlockEmbed {
          static create(value) {
            let node = super.create();
            node.setAttribute("src", res.data.url); //设置audio的src属性
            node.setAttribute("controls", true); //设置audio的controls，否则他将不会显示
            node.setAttribute("controlsList", "nodownload"); //设置audio的下载功能为不能下载
            node.setAttribute("id", "voice"); //设置一个id
            return node;
          }
        }
        AudioBlot.blotName = "audio";
        AudioBlot.tagName = "audio"; //自定义的标签为audio
        Quill.register(AudioBlot);

        // insertEmbed(index: Number(插入的位置), type: String(标签类型), value: any(参数，将传入到create的方法中去), source: String = 'api')
        quill.insertEmbed(length, "audio", res.data.url);
        quill.setSelection(length + 1); //光标位置向后移动一位
      } else {
        this.$message.error("音频插入失败");
      }
    },

    // 富文本图片/视频/音频上传失败
    uploadError() {
      this.$message.error("插入失败");
    },
    onEditorChange() {
      console.log(this.content);
      this.$emit("Editor", this.content);
    },
  },
  mounted() {
    getSystemsettings.then((res) => {
      this.imgsrc = res;
    });
    // console.log(request.defaults.baseURL)
  },
};
</script>

<style>
.avatar-uploader-editor {
  display: inline-block;
}
.avatar-uploader-editor-video {
  display: inline-block;
}
.avatar-uploader-editor-voice {
  display: inline-block;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
  content: "宋体";
  font-family: "SimSun";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
  content: "黑体";
  font-family: "SimHei";
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Microsoft-YaHei"]::before {
  content: "微软雅黑";
  font-family: "Microsoft YaHei";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
  content: "楷体";
  font-family: "KaiTi";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
  content: "仿宋";
  font-family: "FangSong";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  content: "Arial";
  font-family: "Arial";
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Times-New-Roman"]::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Times-New-Roman"]::before {
  content: "Times New Roman";
  font-family: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
  content: "sans-serif";
  font-family: "sans-serif";
}

.ql-font-SimSun {
  font-family: "SimSun";
}

.ql-font-SimHei {
  font-family: "SimHei";
}

.ql-font-Microsoft-YaHei {
  font-family: "Microsoft YaHei";
}

.ql-font-KaiTi {
  font-family: "KaiTi";
}

.ql-font-FangSong {
  font-family: "FangSong";
}

.ql-font-Arial {
  font-family: "Arial";
}

.ql-font-Times-New-Roman {
  font-family: "Times New Roman";
}

.ql-font-sans-serif {
  font-family: "sans-serif";
}
.ql-snow .ql-picker-label::before {
  vertical-align: 10px !important;
}
.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  vertical-align: 8px !important;
}
strong {
  font-weight: bolder !important;
}
</style>
