<template>
	<el-card class="box-card">
		<div slot="header" class="clearfix">
			<h3>
				<el-tooltip class="item" effect="dark" content="返回上一页" placement="bottom">
				    <i @click="goBack" style="margin-right: 40px; cursor: pointer;" class="el-icon-arrow-left"></i>
				</el-tooltip>
				
				{{parseInt(this.$route.query.titletype)|typename}}
			</h3>
		</div>
		<div style="width: 60%;">
		<el-form ref="form" :model="form" label-width="80px">
		
			<!-- <el-form-item label="选择课程">
				<span style="color: #0275D8;" @click="dialogVisible = true">{{name==''?'选择课程':name}}</span>

			</el-form-item> -->
		  <el-form-item label="章节名称">
		    <el-input  :disabled="this.$route.query.titletype==1?true:false" v-model="form.name"></el-input>
		  </el-form-item>
		  <el-form-item label="排序">
		    <el-input  :disabled="this.$route.query.titletype==1?true:false" v-model="form.sort"></el-input>
		  </el-form-item>
		  <el-form-item label="章节标题">
		    <el-input :disabled="this.$route.query.titletype==1?true:false"  v-model="form.title"></el-input>
		  </el-form-item>
		  <el-form-item label="章节关键词">
		    <el-input :disabled="this.$route.query.titletype==1?true:false"  v-model="form.keyword"></el-input>
		  </el-form-item>
<!-- 		  <el-form-item label="排序">
		    <el-input :disabled="this.$route.query.titletype==1?true:false"  v-model="form.sort"></el-input>
		  </el-form-item> -->
		  
<!-- 		<el-form-item label="缩略图">
		  <el-upload
		  :disabled="this.$route.query.titletype==1?true:false"
		    class="avatar-uploader"
		    :action="serverUrl"
			name='image'
		    :show-file-list="false"
		    :on-success="handleAvatarSuccess"
			:headers="header" 
			:data="data"
		    :before-upload="beforeAvatarUpload">
		    <img v-if="form.thumbnail" :src="form.thumbnail" class="avatar">
		    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		  </el-upload>
		</el-form-item> -->
<!-- 			<el-form-item label="课程说明">
			  <el-input :disabled="parseInt(this.$route.query.type)==1?true:false"  v-model="form.course_description"></el-input>
			</el-form-item> -->

			
<!-- 			<el-form-item v-if="this.$route.query.type==2" label="视频">
			  <el-upload
			  :disabled="this.$route.query.titletype==1?true:false"
			    class="avatar-uploader"
			    :action="serverUrl"
				name='image'
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess1"
				:headers="header" 
				:data="data"
			    :before-upload="beforeAvatarUpload">
			    <video v-if="form.video_url" controls="controls" :src="form.video_url" class="avatar"></video>
			    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  </el-upload>
			</el-form-item> 
			 <el-form-item v-if="this.$route.query.type==2" label="音频">
			  <el-upload
			  :disabled="this.$route.query.titletype==1?true:false"
			    class="avatar-uploader"
			    :action="serverUrl"
				name='image'
			    :show-file-list="false"
			    :on-success="handleAvatarSuccess2"
				:headers="header" 
				:data="data"
			    :before-upload="beforeAvatarUpload">
				<audio  v-if="form.audio_url" :src="form.audio_url" controls="controls">
				</audio>
			    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
			  </el-upload>
			</el-form-item> -->
		  <!-- <el-form-item  v-if="this.$route.query.type==1" label="图文信息">
		        <div class="edit_container" style="height: 400px; position: relative;">
					<editor :content="content" @Editor="Editor"></editor>
				</div>	
		  </el-form-item> -->
		  <el-form-item v-if="this.$route.query.titletype!=1">
		    <el-button type="primary" @click="onSubmit">确定</el-button>
		    <!-- <el-button>取消</el-button> -->
		  </el-form-item>
		</el-form>
		</div>
		<el-dialog
		  title="选择课程"
		  :visible.sync="dialogVisible"
		  width="30%">
		  <el-radio @change="change" v-for="(item,index) in goods_list" v-model="radio" :label="index" :key="index">{{item.name}}</el-radio>
		  <!-- <div>{{item.name}}</div> -->
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>
	</el-card>
</template>	
<script>
	// editor
	import request from "@/api/http.js";
	import editor from '../../components/editor/index.vue'
	import {
	  classifyList,
	  CourseAdd,
	  CourseList,
	  addNewsCategory,
	  findNewsCateDetail,
	  delNewsCate,
	  content_Save,
	  updataImg,
	  newsSave,
	  chapterSave
	} from "@/api/api.js";
	// import UpImg from "@/components/upImg.vue";
	export default {
		components: {
			editor,
			 // UpImg 
		},
		data() {
			return {
				header:{AdminToken:localStorage.getItem('admin_token')},
				serverUrl: request.defaults.baseURL+'/backend/uploadsfile/upload_image', //上传的图片服务器地址
				data:{
					type:'addnews'
					
				},
				name:'',
				dialogVisible:false,
				type:0,
				goods_list:[],
				imageUrl:'',
				radio:-1,
				optionProps: {
				  value: "id",
				  label: "name",
				  children: "children",
				  checkStrictly: true,
				},
				options:[],
				content:'',
				form: {
					pid:'',
					sort:'',
					name:'',
					title:'',
					thumbnail:'',
					keyword:''

				
				}
			}
		},

		mounted() {
			this.getlist()
			console.log(this.$route.query.type)
			if(this.$route.query.row){
				this.form=JSON.parse(this.$route.query.row)
				this.form.id=JSON.parse(this.$route.query.row).id
				this.content=JSON.parse(this.$route.query.row).content
				console.log(this.content)
			}
			this.form.pid=parseInt(this.$route.query.id)
			// this.type=this.$route.query.type
			
		},
		filters:{
			typename(val){
			    let chargename = '';
			    switch (val) {
			        case 0:
			            chargename = '添加章节';
			            break;
			        case 1:
			            chargename = '查看章节';
			            break;
			        case 2:
			            chargename = '编辑章节';
			            break;
			
			    }
			    return chargename;
			},
		},
		methods: {
			change(e){
				console.log(this.goods_list[e])
				this.type=this.goods_list[e].type
				this.name=this.goods_list[e].name
				this.form.course_id=this.goods_list[e].id
			},
			handleAvatarSuccess(res,file){
				
				console.log(res,file)
				// this.imageUrl=res.data.imgurl
				this.form.thumbnail=res.data.imgurl
			},
			handleAvatarSuccess1(res,file){
				this.form.video_url=res.data.imgurl
			},
			handleAvatarSuccess2(res,file){
				this.form.audio_url=res.data.imgurl
			},
			beforeAvatarUpload(file){
				console.log(file)
				  let videoUrl = URL.createObjectURL(file);
				  let audioElement = new Audio(videoUrl);
				  audioElement.addEventListener("loadedmetadata", () => {
					let duration = audioElement.duration; //时长为秒，小数，182.36
					console.log(duration)
					this.form.duration=duration
				  });
			},
			Editor(e){
				this.form.content=e
			},
			setclass(e){
				console.log(e)
				console.log(this.form.cid)
				this.form.cid=this.form.cid[this.form.cid.length-1]
				// for(var i=0;i<this.form.cid.length; i++){
					
				// }
			},
			onSubmit() {
				// console.log(this.form.radio)
				console.log('submit!');
				// console.log(editor)
				this.newsSave()
			},
			goBack() {
				console.log('go back');
				this.$router.back(-1)
			},
			// 添加
			async newsSave() {
				
			    const { data } = await chapterSave(this.form);
				console.log(data)
			    if (data.code != 200) return this.$message.error(data.data);
				this.$message({
				  message: data.data,
				  type: 'success'
				});
				setTimeout(()=>{
					this.$router.back(-1)
				},500)
				
			    // this.list = data.data.classify_list;
				// this.options=data.data.classify_list;
			},
			// 获取新闻分类列表
			async getlist(keyword) {
			    const { data } = await CourseList({keyword:keyword});
				console.log(data)
			    if (data.code != 200) return this.$message.error(data.data);
			    this.goods_list = data.data.goods_list.data;
			},
		},
		
	}
</script>	
<style>
	.edit_container .ql-editing{
		left: 0 !important;
	}
	  .avatar-uploader .el-upload {
	    border: 1px dashed #d9d9d9;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	  .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	  .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 178px;
	    height: 178px;
	    line-height: 178px;
	    text-align: center;
	  }
	  .avatar {
	    width: 178px;
	    height: 178px;
	    display: block;
	  }
</style>